import React from 'react'
import Helmet from 'react-helmet'
import styles from './index.module.css'
import Layout from '../components/layout'
import mail from '../images/mail.png'
import heroImage from '../images/ok.png'
import SignupWithEmail from '../components/SignupWithEmail'

const IndexPage = ({ data }) => (
  <Layout>
    <Helmet
      meta={[
        { property: 'og:image', content: 'https://remotejobsclub.com' + data.file.childImageSharp.fixed.src },
        { property: 'og:image:width', content: data.file.childImageSharp.fixed.width },
        { property: 'og:image:height', content: data.file.childImageSharp.fixed.height },
        { name:     'twitter:card', content: 'summary_large_image' },
        { name:     'twitter:image', content: 'https://remotejobsclub.com' + data.file.childImageSharp.fixed.src },
      ]}
    />
    <div className={styles.root}>
      <div className={styles.hero}>
        <h1>Remote jobs delivered to your inbox. <img alt="paper plane" src={mail} /></h1>
        <p>
          Get free bi-weekly email roundup of remote jobs that aren't restricted by commutes or a particular geographic area.
        </p>
        <p>
          Join <b>7,500+</b> people getting <i><b>hand curated remote jobs</b></i>.
        </p>

        <SignupWithEmail />

        <div className={styles.jobTypes}>
          <div>
            <div>Frontend/Backend dev jobs</div>
            <div>UI/UX jobs</div>
            <div>Marketing and sales jobs</div>
          </div>
          <div>
            <div>Design jobs</div>
            <div>Content writing jobs</div>
            <div>.. and other interesting jobs</div>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <img alt="hero" src={heroImage} />
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "card.png" }) {
      childImageSharp {
        fixed {
          src
          width
          height
        }
      }
    }
  }
`

export default IndexPage
